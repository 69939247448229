import React from "react";

const AMLKYC = () => {
    return (
        <div className="py-[12px]">
            <h1 className="text-center font-medium font-roboto  text-[22px]">AML & KYC Regulations</h1>
            <div className="mx-[10%] my-3">
                <h3 className="font-roboto font-medium mb-2">Introduction</h3>
                <p className="mb-6">Bluebucks Tech IT Solutions Pvt. Ltd. (the parent company of You Ramp Money) is committed to complying with all applicable Anti-Money Laundering (AML) and Know Your Customer (KYC) regulations to prevent illegal activities such as money laundering, terrorist financing, and other financial crimes.</p>
                <h3 className="mt-[12px] font-roboto font-medium">Know Your Customer (KYC) Policy</h3>
                <p className="mb-6">We implement a thorough KYC process to verify the identity of our customers. This process includes:</p>
                <h3 className="mt-[12px] font-roboto font-medium">Customer Identification</h3>
                <div className="ml-[10px]">
                    <ul className="">
                        <li>Customers are required to provide valid identification documents, including government-issued ID, passport, or driver&apos;s license.</li>
                        <li>Proof of address is also required, such as a utility bill or bank statement.</li>                        
                    </ul>
                </div>
                <h3 className="mt-[12px] font-roboto font-medium">Customer Verification</h3>
                <div className="ml-[10px]">
                    <ul className="">
                        <li>We verify the authenticity of identification documents through reliable and independent sources.</li>
                        <li>Enhanced verification processes are implemented for high-risk customers.</li>                        
                    </ul>
                </div>
                <h3 className="mt-[12px] font-roboto font-medium">Ongoing Monitoring</h3>
                <div className="ml-[10px]">
                    <ul className="">
                        <li>Customer information is regularly updated and monitored for any suspicious activity.</li>
                        <li>Transactions are reviewed to ensure they are consistent with the customer’s profile and expected behavior.</li>                        
                    </ul>
                </div>
                <h3 className="mt-[12px] font-roboto font-medium">Anti-Money Laundering (AML) Policy</h3>
                <p className="mb-6">Our AML policy is designed to detect and prevent money laundering and terrorist financing activities. Key components include:</p>
                <h3 className="mt-[12px] font-roboto font-medium">Risk Assessment</h3>
                <div className="ml-[10px]">
                    <ul className="">
                        <li>Conduct regular risk assessments to identify and mitigate potential AML risks.</li>
                        <li>Classify customers into risk categories based on their profiles and transaction behavior.</li>                        
                    </ul>
                </div>
                <h3 className="mt-[12px] font-roboto font-medium">Transaction Monitoring</h3>
                <div className="ml-[10px]">
                    <ul className="">
                        <li>Implement systems to monitor and analyze customer transactions for unusual or suspicious activity.</li>
                        <li>Transactions above a certain threshold are flagged and investigated further.</li>                        
                    </ul>
                </div>
                <h3 className="mt-[12px] font-roboto font-medium">Suspicious Activity Reporting</h3>
                <div className="ml-[10px]">
                    <ul className="">
                        <li>Report any suspicious activities to the relevant authorities in accordance with regulatory requirements.</li>
                        <li>Maintain records of all suspicious activity reports (SARs) and provide them to authorities upon request.</li>                        
                    </ul>
                </div>
                <h3 className="mt-[12px] font-roboto font-medium">Record Keeping</h3>
                <div className="ml-[10px]">
                    <ul className="">
                        <li>Maintain comprehensive records of customer identification, transaction history, and AML investigations for a minimum period as required by law.</li>
                        <li>Ensure all records are readily accessible for compliance reviews and audits.</li>                        
                    </ul>
                </div>
                <h3 className="mt-[12px] font-roboto font-medium">Employee Training</h3>
                <div className="ml-[10px]">
                    <ul className="">
                        <li>Regular training programs for employees to stay updated on AML and KYC regulations and to recognize suspicious activities.</li>
                        <li>Ensure employees are aware of the procedures for reporting suspicious activities.</li>                        
                    </ul>
                </div>
                <h3 className="mt-[12px] font-roboto font-medium">Compliance Officer</h3>
                <div className="ml-[10px]">
                    <ul className="">
                        <li>Appoint a dedicated AML Compliance Officer responsible for overseeing the implementation and effectiveness of our AML and KYC policies.</li>
                        <li>The Compliance Officer ensures that the company adheres to all relevant legal and regulatory requirements.</li>                        
                    </ul>
                </div>
                <h3 className="mt-[12px] font-roboto font-medium">Customer Cooperation</h3>
                <div className="ml-[10px]">
                    <ul className="">
                        <li>Customers are required to cooperate fully with our KYC and AML procedures.</li>
                        <li>Failure to provide required information or documentation may result in account suspension or termination.</li>                        
                    </ul>
                </div>
                <p className="mb-6">Therefore Bluebucks Tech IT Solutions Pvt. Ltd. is dedicated to maintaining the highest standards of AML and KYC compliance. By adhering to these regulations, we aim to create a secure and transparent environment for all our customers and stakeholders.</p>
                <p className="mb-6">For any questions or further information regarding our AML and KYC policies, please contact our compliance team.</p>
                
            </div>
        </div>
    );
}

export default AMLKYC;