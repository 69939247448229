import React from "react";

const Terms = () => {
    return (
        <div className="py-[12px]">
            <h1 className="text-center font-medium font-roboto  text-[22px]">Terms and Conditions</h1>
            <div className="mx-[10%] my-3">
                <h3 className="font-roboto font-medium mb-2">Introduction</h3>
                <p className="mb-6">Welcome to YouRamp Money. These Terms and Conditions govern your use of our website, available at https://www.youramp.money/ . By using our site, you agree to these terms. If you disagree, please do not use our site. Users must be at least 18 years old.</p>
                <h3 className="mt-[12px] font-roboto font-medium">Intellectual Property Rights</h3>
                <p className="mb-6">Unless otherwise noted, YouRamp Money and its licensors own all intellectual property rights to the content on this website. You are granted a limited license to view this material.</p>
                <h3 className="mt-[12px] font-roboto font-medium">Restrictions</h3>
                <p className="mb-6">You are restricted from:</p>
                <div className="ml-[10px]">
                    <ul className=" list-decimal">
                        <li>Republishing website content without permission</li>
                        <li>Selling, sublicensing, or commercializing website material</li>
                        <li>Publicly performing or displaying website material</li>
                        <li>Using the website in ways that could damage it or restrict user access</li>
                        <li>Violating any applicable laws and regulations while using the site</li>
                        <li>Engaging in data mining, harvesting, or similar activities</li>
                        <li>Using the website for advertising or marketing purposes without consent</li>
                    </ul>
                </div>
                <h3 className="mt-[12px] font-roboto font-medium">Your Content</h3>
                <p className="mb-6">By posting content on YouRamp Money, you grant us a non-exclusive, worldwide, irrevocable license to use, reproduce, and distribute your content. You must ensure your content is lawful and does not infringe on any third party&apos;s rights. We reserve the right to remove any of your content from the website at our discretion.</p>
                <h3 className="mt-[12px] font-roboto font-medium">No Warranties</h3>
                <p className="mb-6">YouRamp Money is provided &quot;as is,&quot; without any warranties or guarantees of any kind. We do not make any representations about the accuracy or completeness of the content on our website.</p>
                <h3 className="mt-[12px] font-roboto font-medium">Limitation of Liability</h3>
                <p className="mb-6">YouRamp Money will not be held liable for any damages arising from the use of our website. This includes direct, indirect, incidental, consequential, or punitive damages. Your use of our website is at your own risk, and you assume full responsibility for any loss resulting from your use.</p>
                <h3 className="mt-[12px] font-roboto font-medium">Indemnification</h3>
                <p className="mb-6">By using YouRamp Money, you agree to indemnify and hold us harmless from any claims, damages, liabilities, costs, or expenses arising from your use of the website or violation of these terms. This includes any third-party claims related to your content or activities on the site. You must not use the website if you disagree with any of these terms. Users must be at least 18 years old.</p>
            </div>
        </div>
    );
}

export default Terms;