import React from "react";

const Privacy = () => {
    return (
        <div className="py-[12px]">
            <h1 className="text-center font-medium font-roboto  text-[22px]">Privacy Policy</h1>
            <div className="mx-[10%] my-3">
                <h3 className="font-roboto font-medium mb-2">Information We Collect</h3>
                <p className="mb-6">We collect various types of data to improve and provide our services.</p>
                <ul className="my-3">
                    <li><span className="font-medium">Personal Information: </span>When you create an account, we may collect your name, email address, and contact details to facilitate account creation, provide customer support, and communicate with you about our services.</li>
                    <li><span className="font-medium">Financial Information:</span> To facilitate transactions and comply with legal requirements, we collect financial information such as payment method details and cryptocurrency wallet addresses.</li>
                    <li><span className="font-medium">Transaction Information:</span>We collect details about your transactions on our platform, including transaction history, amounts, and recipient information to process transactions and provide relevant information.</li>
                    <li><span className="font-medium">Device Information:</span>We collect data about the device you use to access our services, including IP address, browser type, operating system, and usage patterns to improve our services and enhance security.</li>
                    <li><span className="font-medium">Cookies and Similar Technologies: </span>We use cookies and similar technologies to collect information about your interactions with our services, including browsing behavior and preferences.</li>
                </ul>

                <h3 className="font-roboto font-medium mb-2">How We Use Your Information</h3>
                
                <ul className="my-3">
                    <li><span className="font-medium">Providing Services:</span> We use your information to facilitate transactions, process payments, and provide customer support.</li>
                    <li><span className="font-medium">Improving Services: </span>We analyze usage patterns, troubleshoot issues, and enhance the functionality of our platform.</li>
                    <li><span className="font-medium">Communication:  </span>We send you transaction confirmations, updates, and promotional materials.</li>
                    <li><span className="font-medium">Legal Compliance:</span>We comply with legal obligations, respond to legal requests, and prevent fraudulent activities.</li>
                    <li><span className="font-medium">Marketing </span>With your consent, we may use your information to send marketing communications about our services and related products.</li>

                </ul>
                <h3 className="font-roboto font-medium mb-2">Information Sharing</h3>               
                <ul className="my-3">
                    <li><span className="font-medium">With Service Providers: </span> We may share your personal information with third-party service providers who assist us in providing and maintaining our services. These providers have access only to perform tasks on our behalf and must not use it for any other purpose.</li>
                    <li><span className="font-medium">For Legal Reasons: </span>We may disclose your information to comply with legal obligations, respond to legal requests, or protect our rights, privacy, safety, or property. This includes sharing with law enforcement or government entities as required by law.</li>
                    <li><span className="font-medium">With Your Consent:</span>We may share your information for other purposes if we have your explicit consent. You can withdraw your consent at any time by contacting us.</li>
                    <li><span className="font-medium">Business Transactions: </span>In case of a merger, acquisition, or other business transaction, your information may be transferred to another entity. We will notify you if your information is transferred and subject to a different privacy policy.</li>
                    <li><span className="font-medium">Aggregated and De-Identified Data: </span>We may share aggregated and de-identified data that does not personally identify you for statistical analysis, research, and improving our services.</li>
                </ul>

                <h3 className="mt-[12px] font-roboto font-medium">Your Choices</h3>
                <ul className="my-3">
                    <li><span className="font-medium">Account Information:</span> You can review and update your account information by logging into your account and adjusting your profile settings. Contact us for changes or account deletion.</li>
                    <li><span className="font-medium">Communication Preferences:</span>Opt in or out of promotional communications by adjusting your preferences in your account settings or unsubscribing from marketing emails using the provided link.</li>
                    <li><span className="font-medium">Cookie Settings: </span>Manage your cookie preferences in your browser settings. Note that some features of our services may not function properly without cookies.</li>
                    <li><span className="font-medium">Withdraw Consent: </span>You can withdraw your consent for the use of your personal information at any time by contacting us.</li>
                    <li><span className="font-medium">Do Not Track Signals:  </span>Our services do not currently respond to &apos;Do Not Track&apos; &#40;DNT&#41; signals, but you can adjust your preferences in your browser settings.</li>
                </ul>
            </div>
        </div>
    );
}

export default Privacy;