import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/* eslint-disable react/prop-types */
const Layout = ({children}) => {
    return (
        <>
            <ToastContainer/>
            <Header />
            {children}
            <Footer />
        </>
    );
}

export default Layout;