import React, { useState } from "react";
import bannerVideo from "./assets/videos/banner.mp4";
import section3ImageTwo from "./assets/images/Basket.png";
import section3ImageOne from "./assets/images/Group.png";
import section3ImageThree from "./assets/images/money.png";
import growthFirst from "./assets/images/growth-first.png";
import growthSecond from "./assets/images/growth-second.png";
import growthThird from "./assets/images/growth-third.png";
import growthFourth from "./assets/images/growth-fourth.png";
import growthFifth from "./assets/images/growth-fifth.png";
import growthSixth from "./assets/images/growth-sixth.png";
import tokenFirst from "./assets/images/token/money.png";
import tokenSecond from "./assets/images/token/real-time.png";
import tokenthird from "./assets/images/token/history.png";
import tokenFourth from "./assets/images/token/market-research.png";
import tokenFifth from "./assets/images/token/easy-entry.png";
import tokenSixth from "./assets/images/token/expend.png";
import cryptoFirst from "./assets/images/crypto/brush.png";
import cryptoSecond from "./assets/images/crypto/crisis-management.png";
import cryptothird from "./assets/images/crypto/graph.png";
import cryptoFourth from "./assets/images/crypto/money-management.png";
import cryptoFifth from "./assets/images/crypto/money.png";
import bitcoin from "./assets/images/bitcoin.png";
import binance from "./assets/images/binance.png";
import tron from "./assets/images/tron.png";
import tether from "./assets/images/tether.png";
import ethereum from "./assets/images/ethereum.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from 'react-toastify';
const Home = () => {
  const [tabSection, setTabSection] = useState("growth");
  const [contactSubmit , setContactSubmit] = useState(false);
  const formik = useFormik({
    initialValues:{
      name : "",
      email : "",
      message : ""
    },
    validationSchema : Yup.object().shape({
      name : Yup.string().required("Name is required"),
      email : Yup.string().email().required("Email is required"),
      message : Yup.string().required("Message is required")
    }),
    onSubmit : (values) => {
      setContactSubmit(true)
      axios.post(`${process.env.REACT_APP_API_URL}LandingContactSupport`,{
        Email : values.email,
        Name : values.name,
        Message : values.message
      }).then((res) => {
        setContactSubmit(false)
        toast.success(res.data?.message)
      }).catch((e) => {
        setContactSubmit(false)
        toast.error(e.message)
      })
    }
  })
  return (
    <>
      <section className='relative h-[100vh]'>
        <div className="video-background  w-full h-full ">
          <video className='w-full h-full object-cover ' autoPlay loop muted playsInline>
            <source src={bannerVideo} type="video/mp4" />
          </video>
        </div>
        <div className='video-section absolute h-full w-full top-0'></div>

        <div className="content absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-white text-center">
          <h1 className='text-[20px] sm:text-[25px] md:text-[50px] font-roboto font-bold'>Your Fiat On-Ramp Solution to the <span className='text-[#EFB0FF]'>World of Web3</span></h1>
          <p className='text-[16px] sm:text-[16px] md:text-[20px] text-[#D3C1D4] mb-[24px]'>You Ramp Money seamlessly bridges traditional finance to the world of cryptocurrencies with simplified and secured investment opportunities.</p>
          <a rel="noreferrer" target='_blank' href={`${process.env.REACT_APP_USER_DASHBOARD_URL}`} className='text-[12px] sm:text-[16px] md:text-[20px] text-[#54006E] bg-[#EFB0FF] px-4 rounded-[8px] py-2 mt-[24px]'>Buy & sell crypto</a>
        </div>
      </section>
      <section className='bg-[#F8E9F7] grid grid-cols-1 md:grid-cols-2 gap-1'>
        <div className='flex flex-col justify-center items-start mx-[30px]  md:mx-[90px] my-[15px]  md:my-[25px] md:my-[90px] px-[20px]  py-[15px] md:py-[30px]'>
          <p className='text-left text-[#700093] text-[12px] md:text-[16px] font-roboto font-medium '>Making Crypto Investing Easy Like Never Before</p>
          <h1 className='text-left text-[#211922] text-[20px] md:text-[46px] font-roboto font-medium'>Global Fiat On-Ramp Solution</h1>
        </div>
        <div className='flex border border-1 border-[#857687] rounded-[16px] flex-col justify-center items-center mx-[30px] my-[15px] md:my-[25px] md:my-[50px] px-[30px] md:px-[90px] lg:px-[50px] xl:px-[35px] py-[15px]  md:py-[30px]'>
          <p className='text-[#211922] text-[15px] md:text-[31px] font-SpaceGrotesk font-bold'>Fiat to crypto on-ramp</p>
          <h1 className='text-[#4C3E4E] text-[9px] md:text-[12px] lg:text-[14px] font-Mulish font-semibold text-center leading-[19.6px]'>Say goodbye to the complexities of entering the web3 world. Our on-ramp solution paves the way for a smoother transition by converting your traditional fiat currency into cryptocurrency.</h1>
          <a rel="noreferrer" target='_blank' href={process.env.REACT_APP_USER_DASHBOARD_URL} className='font-medium text-[#FFFFFF] text-[10px] md:text-[15px] bg-[#700093] px-[32px] py-[16px] rounded-[12px]  mt-[24px]'>Buy & sell crypto</a>
        </div>
      </section>
      <section className='mx-auto bg-[#FFF7FB]'>
        <h1 className='pt-[60px] text-center text-[20px] md:text-[46px]  font-roboto500'>Explore our Suite of Investment Solutions</h1>
        <p className='text-center text-[9px] md:text-[18px] font-roboto'>At You Ramp Money, we offer a range of innovative products designed to empower individuals to explore and invest. It includes:</p>
        <div className='grid grid-cols-1 md:grid-cols-3 mx-[25px] md:mx-[110px] mt-[24px]'>
          <div className='p-6' >
            <img className='mb-3 p-2 border border-1 rounded-[12px] border-[#857687] w-[50px] md:w-[70px]' src={section3ImageThree} alt='section-three-third' />
            <h1 className='font-roboto600 text-[12px] md:text-[24px] text-[#211922]'>Token Treasury</h1>
            <p className='font-roboto text-[10px] md:text-[18px] leading[0.5px] text-[#4C3E4E] mt-2'>An extensive list of cryptocurrencies for direct investment.</p>
          </div>
          <div className='p-6'>
            <img className='mb-3 p-2 border border-1 rounded-[12px] border-[#857687] w-[50px] md:w-[70px]' src={section3ImageOne} alt='section-three-first' />
            <h1 className='font-roboto600 text-[12px] md:text-[24px] text-[#211922]'>Crypto Baskets</h1>
            <p className='font-roboto text-[10px] md:text-[18px] leading[0.5px] text-[#4C3E4E] mt-2'>Curated collections of cryptocurrencies based on specific categories.</p>
          </div>
          <div className='p-6'>
            <img className='mb-3 p-2 border border-1 rounded-[12px] border-[#857687] w-[50px] md:w-[70px]' src={section3ImageTwo} alt='section-three-second' />
            <h1 className='font-roboto600 text-[12px] md:text-[24px] text-[#211922]'>Growth Safe</h1>
            <p className='font-roboto text-[10px] md:text-[18px] leading[0.5px] text-[#4C3E4E] mt-2'>Invest your fiat currency to gain attractive return on investments and beat inflation like a boss!</p>
          </div>
        </div>
        <div className='flex justify-center items-center pb-[40px]'>
          <a href="#contact" className='cursor-pointer px-6 py-3 rounded-[12px] bg-[#324A00] text-[12px] md:text-[16px] text-white border border-1 border-[#324A00]'>Contact Sales</a>          
        </div>
      </section>
      <section>
        <div className='flex flex-col my-[7px] mx-[20px]  sm:flex-row justify-center border-b border-b-1 border-[#4C3E4E]'>
          <button className={`text-[#2B202E] text-[12px] md:text-[18px] font-roboto leading-[0.5px] px-4 py-[20px] border-b border-b-1 border-b border-b-1 hover:border-[#3D0052] hover:bg-[#F0DCF0] ${tabSection === "crypto" ? "border-[#3D0052] bg-[#F0DCF0]" : ""}`} onClick={() => setTabSection("crypto")}>Crypto Baskets</button>
          <button className={`text-[#2B202E] text-[12px] md:text-[18px] font-roboto leading-[0.5px] px-4 py-[20px] border-b border-b-1 border-b border-b-1 hover:border-[#3D0052] hover:bg-[#F0DCF0] ${tabSection === "growth" ? "border-[#3D0052] bg-[#F0DCF0]" : ""} `} onClick={() => setTabSection("growth")}>Growth Safe</button>
          <button className={`text-[#2B202E] text-[12px] md:text-[18px] font-roboto leading-[0.5px] px-4 py-[20px] border-b border-b-1 border-b border-b-1 hover:border-[#3D0052] hover:bg-[#F0DCF0] ${tabSection === "token" ? "border-[#3D0052] bg-[#F0DCF0]" : ""}`} onClick={() => setTabSection("token")}>Token Treasury</button>
        </div>
        {tabSection === "growth" && <div className='mx-[15%] mt-[30px]'>
          <h1 className='text-[22px] md:text-[46px] text-[#000000] font-roboto500'><span className='text-[#3D0052]'>Growth Safe</span>- Beat Inflation and Grow your Wealth</h1>
          <p className='mt-2 text-[12px] md:text-[18px] text-[#2B202E] font-roboto leading[0.5px]'>Our Growth Safes is your secure path to beating inflation and maximizing returns. We offer a unique opportunity to grow your wealth with confidence. By converting your local currency into a stablecoin, we ensure your funds are shielded from inflation while generating attractive returns through decentralized platforms. Experience the ease and security of our Growth Safes, where your financial future is our top priority.</p>
          <p className='mt-2 text-[12px] md:text-[18px] text-[#3D0052]'>Here’s why you need to get started with our Growth Safe:</p>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-[60px]'>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={growthFirst} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='growth first' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Experience Competitive Returns</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Enjoy an attractive Return on Investment (ROI) with our platform.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={growthSecond} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='growth-second' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Preserve Your Wealth</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Beat inflation and ensure your funds retain their value over time.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={growthThird} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='growth third' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Daily Interest Payouts</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Earn daily interest on your deposits, maximizing your earnings.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={growthFourth} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='growth fourth' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Flexibility and Freedom</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>No lock-in period allows you to deposit and withdraw funds at your convenience..</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={growthFifth} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt="growth fifth" />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Easy Entry with Fiat</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Start investing with ease by depositing fiat currencies.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={growthSixth} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='growth sixth' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Secure Your Future</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Rest assured, your funds are secure and accessible whenever you need them.</p>
            </div>
          </div>
        </div>}
        {tabSection === "crypto" && <div className='mx-[15%] mt-[30px]'>
          <h1 className='text-[22px] md:text-[46px] text-[#000000] font-roboto500'><span className='text-[#3D0052]'>Crypto Baskets</span>- Theme-Based Investment Solutions</h1>
          <p className='mt-2 text-[12px] md:text-[18px] text-[#2B202E] font-roboto leading[0.5px]'>Investing in cryptocurrencies can be both thrilling and challenging. Our crypto baskets offer a unique solution, allowing you to invest in a curated collection of digital assets based on specific themes. These baskets are like treasure chests, each containing a mix of crypto gems carefully selected to maximize potential returns while minimizing risks.</p>
          <p className='mt-2 text-[12px] md:text-[18px] text-[#3D0052]'>Here’s why you need to get started with our Crypto Baskets</p>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-[60px]'>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={cryptoFirst} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='crypto first' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Diversified Themes</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Invest in a variety of assets under a single theme of your choice, ensuring consistent investment across multiple cryptocurrencies.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={cryptoSecond} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='crypto-second' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Risk Diversification</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Mitigate the risks associated with individual cryptocurrencies by spreading your investment across a range of assets.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={cryptothird} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='crypto third' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Streamlined Investment</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Save time and effort on researching individual tokens; our baskets simplify the decision-making process.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={cryptoFourth} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='crypto fourth' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Automated Investing</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Stay disciplined with automated, scheduled purchases, ensuring a systematic approach to your investments.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={cryptoFifth} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt="crypto fifth" />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Flexible Currency Support</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Purchase Crypto Baskets using fiat currencies, providing convenience and accessibility to a global audience.</p>
            </div>
          </div>
        </div>}
        {tabSection === "token" && <div className='mx-[15%] mt-[30px]'>
          <h1 className='text-[22px] md:text-[46px] text-[#000000] font-roboto500'><span className='text-[#3D0052]'>Token Treasury</span>- Your Gateway to Cryptocurrency Diversity</h1>
          <p className='mt-2 text-[12px] md:text-[18px] text-[#2B202E] font-roboto leading[0.5px]'>Our Token Treasury offers a diverse selection of cryptocurrencies for direct investment. It provides you with access to a wide range of digital assets to build a diversified portfolio tailored to your investment goals and risk tolerance.</p>
          <p className='mt-2 text-[12px] md:text-[18px] text-[#3D0052]'>Here’s why you need to get started with our Token Treasury</p>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-[60px]'>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={tokenFirst} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='growth first' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Support for Native Currencies</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Easily purchase crypto assets using fiat currencies.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={tokenSecond} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='growth-second' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Real-Time Price Tracking</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Stay updated with real-time price fluctuations.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={tokenthird} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='growth third' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Historical Data Analysis</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Analyze past data to make informed decisions.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={tokenFourth} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='growth fourth' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Flexibility and Freedom</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Stay informed about current market trends.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={tokenFifth} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt="growth fifth" />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Easy Entry with Fiat</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Build a diversified crypto portfolio effortlessly.</p>
            </div>
            <div className='mb-[25px] md:mb-[60px]' >
              <img src={tokenSixth} className='w-[50px] md:w-[68px] h-[50px] md:h-[68px]' alt='growth sixth' />
              <h1 className='mt-2 text-[#000000] text-[12px] md:text-[24px] font-roboto600 leading-[32px]'>Investment Flexibility</h1>
              <p className='mt-4 text-[#2B202E] text-[10px] md:text-[18px] font-roboto leading-[125%]'>Buy, sell, and trade crypto assets with ease.</p>
            </div>
          </div>
        </div>}
      </section>
      <section className='px-[10%] py-[60px] bg-[#FFF7FB] flex  items-center justify-center'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
          <div className='text-center'>
            <p className='text-[#3D0052] font-roboto500 text-[12px] md:text-[16px] leading-[20px]'>Buying popular cryptocurrencies</p>
            <h1 className='text-[26] md:text-[46px] font-roboto500 leading[52px] text-[#101828]'>Popular tokens available</h1>
            <a rel="noreferrer" target='_blank' href={process.env.REACT_APP_USER_DASHBOARD_URL} className='text-[12px] md:text-[18px] text-[#FFFFFF] bg-[#3D0052] px-4 rounded-[8px] py-2'>Buy & sell crypto</a>
          </div>
          <div>
            <div className='my-[10px] grid grid-cols-3 gap-1 place-items-center'>
              <div className='flex gap-1 text-[12px] md:text-[16px] rounded-[99px] coinshadow bg-white px-4 py-2'>
                <img className='w-[15px] md:w-[20px] h-[15px] md:h-[20px]' src={bitcoin} />
                Bitcoin
              </div>
              <div className='flex gap-1 text-[12px] md:text-[16px] rounded-[99px] coinshadow bg-white px-4 py-2'>
                <img className='w-[15px] md:w-[20px] h-[15px] md:h-[20px]' src={ethereum} />
                Ethereum
              </div>
              <div className='flex gap-1 text-[12px] md:text-[16px] rounded-[99px] coinshadow bg-white px-4 py-2'>
                <img className='w-[15px] md:w-[20px] h-[15px] md:h-[20px]' src={binance} />
                BNB
              </div>
            </div>
            <div className='my-[10px] flex justify-evenly items-center'>
              <div className='flex gap-1 text-[12px] md:text-[16px] rounded-[99px] coinshadow bg-white px-4 py-2'>
                <img className='w-[15px] md:w-[20px] h-[15px] md:h-[20px]' src={tether} />
                USDT
              </div>
              <div className='flex gap-1 text-[12px] md:text-[16px] rounded-[99px] coinshadow bg-white px-4 py-2'>
                <img className='w-[15px] md:w-[20px] h-[15px] md:h-[20px]' src={tron} />
                TRON
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='my-[5%]'>
        <h1 className='text-[#211922] text-center font-roboto500 text-[26px] md:text-[46px]'>Discover financial freedom with <span className='text-[#700093]'>You Ramp Money</span></h1>
        <p className='my-4 text-center text-[12px] md:text-[18px] font-roboto text-[#211922]'>Our platform offers a series of benefits, empowering you to take control of your financial future.</p>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mx-[10%] gap-4'>
          <div className='border border-1 border-[#857687] rounded-[12px] px-4 py-4'>
            <h1 className='mb-1 text-[#211922] font-roboto600 text-[18px] md:text-[24px] leading-[32px]'>Experience Financial Freedom</h1>
            <p className='text-[12px] md:text-[18px] font-roboto text[#211922] mb-2'>Take charge of your financial future with our platform&#39;s innovative tools and services.</p>
          </div>
          <div className='border border-1 border-[#857687] rounded-[12px] px-4 py-4'>
            <h1 className='mb-1 text-[#211922] font-roboto600 text-[18px] md:text-[24px] leading-[32px]'>Diverse Investment Options</h1>
            <p className='text-[12px] md:text-[18px] font-roboto text[#211922] mb-4'>Choose from a wide range of investment options, tailored to suit your individual preferences and goals.</p>
          </div>
          <div className='border border-1 border-[#857687] rounded-[12px] px-4 py-4'>
            <h1 className='mb-1 text-[#211922] font-roboto600 text-[18px] md:text-[24px] leading-[32px]'>User-Friendly Interface</h1>
            <p className='text-[12px] md:text-[18px] font-roboto text[#211922] mb-4'>Whether you&#39;re a beginner or an expert, our platform is designed for ease of use, making it accessible to all.</p>
          </div>
          <div className='border border-1 border-[#857687] rounded-[12px] px-4 py-4'>
            <h1 className='mb-1 text-[#211922] font-roboto600 text-[18px] md:text-[24px] leading-[32px]'>Top-Notch Security</h1>
            <p className='text-[12px] md:text-[18px] font-roboto text[#211922] mb-4'>We prioritize the security and integrity of all transactions, ensuring your assets are always protected.</p>
          </div>
        </div>
        <div className='mx-[10%] border border-1 border-[#857687] rounded-[12px] px-4 py-4 my-4'>
          <h1 className='mb-1 text-[#211922] font-roboto600 text-[18px] md:text-[24px] leading-[32px]'>Global Fiat On-Ramp</h1>
          <p className='text-[12px] md:text-[18px] font-roboto text[#211922] mb-4'>Seamlessly transition into the world of cryptocurrency with our global fiat on-ramp solution, available to users worldwide.</p>
        </div>
        <div className='flex justify-center'>
          <a rel="noreferrer" target='_blank' href={process.env.REACT_APP_USER_DASHBOARD_URL} className='text-[#700093] border border-1 border-[#700093]  px-4 rounded-[8px] py-2'>Buy & sell crypto</a>

        </div>
      </section>
      <section className='map-background py-[50px] px-[10%]'>

        <h1 className='text-[30px] md:text-[64px] font-SpaceGrotesk700 leading[70.4px] text-white'>Ready to Ramp Up Your Investments?</h1>
        <a rel="noreferrer" target='_blank' href={process.env.REACT_APP_USER_DASHBOARD_URL} className='text-[12px] md:text-[18px] text-[#FFFFFF] bg-[#362E37] px-4 rounded-[8px] py-2'>Begin your Crypto Journey</a>

      </section>
      <section id="contact" className="py-[120px] sm:px-[30px] md:px-[40px] bg-[#FFF7FB]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-row justify-center  items-center">
            <div>
              <h1 className="text-center font-normal text-[42px] leading-8">Contact US</h1>
              <p className="text-[#3D0052] font-medium text-center text-[10px]">Let us know more about you</p>
            </div>
          </div>
          <div className="border border-[#D9D9D9] bg-white py-[30px] px-[30px] rounded">
            <form onSubmit={formik.handleSubmit} >
              <div className="flex flex-row justify-end">
                  <img className="w-[70px] h-[70px]" src="./logo.png" />
              </div>
              <div className="w-full my-3">
                <label className="text-[#1E1E1E] my-1 font-normal">Name</label>
                <br />
                <input name="name" value={formik.values.name} onChange={formik.handleChange} className="border border-[#D9D9D9] w-full py-2 px-1 rounded" type="text"  />
                {formik.touched.name ? <div className="error">{formik.errors.name }</div> : null }
              </div>
              <div className="w-full my-3">
                <label className="text-[#1E1E1E]">Email</label>
                <br />
                <input name="email" value={formik.values.email} onChange={formik.handleChange} className="border border-[#D9D9D9] w-full py-2 px-1 rounded" type="email"  />
                {formik.touched.email ? <div className="error">{formik.errors.email }</div> : null }
              </div>
              <div className="w-full my-3">
                <label  className="text-[#1E1E1E]">Message</label>
                <br />
                <textarea name="message" value={formik.values.message} onChange={formik.handleChange} className="border border-[#D9D9D9] w-full py-2 px-1 rounded" ></textarea>
                {formik.touched.message ? <div className="error">{formik.errors.message }</div> : null }
              </div>
              <div >
                <button disabled={contactSubmit} type="submit" className="text-[#F5F5F5] bg-[#2C2C2C] w-[160px] py-1 rounded-md">{contactSubmit ? "Loading..." : "Submit"}</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;