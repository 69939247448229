
import './App.css';
import './assets/css/style.css';
import React from 'react';
import Layout from './Layout';
import Home from './home';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Terms from './Terms';
import Privacy from './privacy';
import AMLKYC from './AMLKYC';
import WhitePaper from './Whitepaper';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <Layout>
              <Home />
            </Layout>
          } />
          <Route path="/terms" element={
            <Layout>
              <Terms />
            </Layout>
          } />
          <Route path="/privacy" element={
            <Layout>
              <Privacy />
            </Layout>
          } />
          <Route path="/aml-kyc-regulations" element={<Layout>
            <AMLKYC />
          </Layout>
          } />
          <Route path="/whitepaper" element={<WhitePaper />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
