import React from "react";
import logo from "../assets/images/logo.png"
const Header = () => {
    return (
        <header>
            <nav className="bg-gray-800">
                <div className="bg-[#0e0e0e] h-[60px] flex justify-between items-center py-2">
                    <div className='ml-4 flex gap-1 justify-start items-center'>
                        <a href="/"><img className='w-[15px] md:w-[20px] lg:w-[25px] xl:w-[30px] 2xl:w-[45px] 3xl:w-[50px]' src={logo} alt='logo' /></a>
                        <h1 className='text-[8px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px] text-white font-breeSerif uppercase'>You Ramp <span className='text-[#EFB0FF] uppercase'>.money</span></h1>
                    </div>
                    <div className="text-white flex gap-4 items-center mr-4 justify-between">
                        <a className="text-[8px] sm:text-[12px] md:text-[15px] 2xl:text-[27px]" target="_blank" href="/whitepaper">Whitepaper</a>
                        <a rel="noreferrer" target='_blank' href={`${process.env.REACT_APP_USER_DASHBOARD_URL}`} className='text-[8px] sm:text-[12px] md:text-[15px] text-[#54006E] bg-[#EFB0FF] px-4 rounded-[8px] py-2'>Buy & sell crypto</a>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;