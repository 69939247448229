import React from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';
import FaceBookIcon from '@mui/icons-material/Facebook';
import footerLogo from "../assets/images/footer-logo.png";


const Footer = () => {
    return(
    <footer>
        <div className='grid grid-cols-1   md:grid-cols-4 bg-[#18111A] px-[50px] py-[60px]' >
          <img className='w-[150px] md:w-[250px] h-[50px] md:h-[100px] my-[16px]' src={footerLogo} alt='footer-logo' />
          <div></div>
          {/* <div className='flex flex-col my-[16px]'>
            <h1 className=' text-[#6C7689]'>Helpful LInks</h1>
            <a className='text-white text-[12px] sm:text-[14px] md:text-[16px] font-roboto py-1'>Help and FAQs</a>
          </div> */}
          <div className='flex flex-col  my-[16px]'>
            <h1 className=' text-[#6C7689]'>Policy</h1>
            
            <a href="/terms" className='text-white text-[12px] sm:text-[14px] md:text-[16px] font-roboto py-1' >Terms and Conditions</a>
            <a href="/privacy" className='text-white text-[12px] sm:text-[14px] md:text-[16px] font-roboto py-1'>Privacy Policy</a>
            <a href="/aml-kyc-regulations" className='text-white text-[12px] sm:text-[14px] md:text-[16px] font-roboto py-1'>AML & KYC Regulations</a>
           
          </div>
          <div className='flex flex-col  my-[16px]'>
            <h1 className='text-[#6C7689]'>Follow Us On</h1>
            <a rel="noreferrer" target="_blank" href="https://wa.me/message/DTBJXTE5LNABL1" className='text-white text-[12px] sm:text-[14px] md:text-[16px] font-roboto py-1'><WhatsAppIcon/> Whatsapp</a>
            {/* <a rel="noreferrer" target="_blank" href="https://linkedin.com" className='text-white text-[12px] sm:text-[14px] md:text-[16px] font-roboto py-1'><LinkedInIcon/> Linkedin</a> */}
            {/* <a rel="noreferrer" target="_blank" href="https://twitter.com" className='text-white text-[12px] sm:text-[14px] md:text-[16px] font-roboto py-1'><TwitterIcon/> Twitter</a>          */}
            <a rel="noreferrer" target="_blank" href="https://www.facebook.com/profile.php?id=61563528982410&mibextid=ZbWKwL" className='text-white text-[12px] sm:text-[14px] md:text-[16px] font-roboto py-1'><FaceBookIcon/> Facebook</a>         
          </div>
        </div>
        <div className='flex justify-center items-center py-2 bg-[#211922]'>
          <p className='text-white text-[#D3C1D4] font-Mulish text-[12px]'>Copyright 2024, .All rights reserved.</p>
        </div>
      </footer>
    );
} 

export default Footer;